import React from 'react';
import Dialog from 'rc-dialog';
import 'rc-dialog/assets/index.css';
import './addNewAnnouncement.scss';
import SmallSixProtection from '../../sixProtection/SmallSixProtection';

import {
	UbtBoundary,
	ChannelUbtGenerator,
	CHANNEL_UBT_GROUP_TYPES,
	CHANNEL_UBT_KEY_TYPES,
} from '../../../ubt.v2/channel';

const ServiceContent = ({ showCommitment, onCommitmentClick, onHideCommitment }) => {
	return (
		<div>
			{window.GlobalSwitches.showNewSixProtection ? (
				<div>
					<SmallSixProtection {...{ globalConfigs: window.GlobalConfigs }} />
					<hr
						style={{
							margin: '0 auto',
							padding: 0,
							width: 1178,
							border: 'none',
							borderBottom: '1px solid #dedede',
						}}
					/>
				</div>
			) : (
				<ul className="service-list">
					<li className="safe-service">
						<UbtBoundary
							tracker={ChannelUbtGenerator(
								CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
								CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
								{ type: 'sericeNotice', text: '服务保障' }
							)}
						>
							<a
								href="//pages.ctrip.com/commerce/promote/201410/flight/jp/index.html#ctm_ref=fli_hp_slg_def_t_1"
								target="_blank"
								rel="noopener noreferrer"
							>
								<i className="iconf-service">&#xe670;</i>
								<div className="servic-item-con">
									<p className="service-title">放心的服务</p>
									<p className="service-des">10000名客服，7×24小时在线</p>
								</div>
							</a>
						</UbtBoundary>
					</li>

					<li className="safe-price">
						<UbtBoundary
							tracker={ChannelUbtGenerator(
								CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
								CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
								{ type: 'sericeNotice', text: '服务保障' }
							)}
						>
							<a
								href="//pages.ctrip.com/commerce/promote/201410/flight/jp/index.html#ctm_ref=fli_hp_slg_def_t_1"
								target="_blank"
								rel="noopener noreferrer"
							>
								<i className="iconf-service">&#xe671;</i>
								<div className="servic-item-con">
									<p className="service-title">放心的价格</p>
									<p className="service-des">价格有保证，出行有保障</p>
								</div>
							</a>
						</UbtBoundary>
					</li>
					<li className="safe-promise">
						<UbtBoundary
							tracker={ChannelUbtGenerator(
								CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
								CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
								{ type: 'sericeNotice', text: '服务保障' }
							)}
						>
							<a href="javascript:;" onClick={onCommitmentClick}>
								<i className="iconf-service">&#xe66f;</i>
								<div className="servic-item-con">
									<p className="service-title">交易平台承诺书</p>
									<p className="service-des">诚信的购票环境，安全的交易平台</p>
								</div>
							</a>
						</UbtBoundary>
					</li>
					<Dialog
						visible={showCommitment}
						maskClosable={true}
						destroyOnClose={true}
						onClose={onHideCommitment}
					>
						<div style={{ textAlign: 'center' }}>
							<img src="https://pic.c-ctrip.com/fltcommon/index/commitment-detail.jpg" alt="" />
						</div>
					</Dialog>
				</ul>
			)}
		</div>
	);
};

const ServiceNotice = ({ ...props }) => {
	return (
		<div className="service-guard">
			<ServiceContent {...props} />
			<div className="service-notice-con">
				<div className="service-notice-left">
					<h2 className="service-notice-title">服务公告</h2>
					<div className="ul-box-special">
						<ul className="notice-list">
							<li>
								<UbtBoundary
									tracker={ChannelUbtGenerator(
										CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
										CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
										{ type: 'sericeNotice', text: '服务公告' }
									)}
								>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="//pages.ctrip.com/marketing/news.htm?file=2018122801"
									>
										机票预订服务提供方：上海华程西南国际旅行社有限公司
									</a>
								</UbtBoundary>
							</li>
							<li>
								<UbtBoundary
									tracker={ChannelUbtGenerator(
										CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
										CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
										{ type: 'sericeNotice', text: '服务公告' }
									)}
								>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="//pages.ctrip.com/marketing/news.htm?file=2016093001"
									>
										关于禁止携带危险品乘机的通知
									</a>
								</UbtBoundary>
							</li>
							<li>
								<UbtBoundary
									tracker={ChannelUbtGenerator(
										CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
										CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
										{ type: 'sericeNotice', text: '服务公告' }
									)}
								>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="//pages.ctrip.com/marketing/news.htm?file=2016051101"
									>
										关于欺诈短信电话的提醒
									</a>
								</UbtBoundary>
							</li>
						</ul>
						<ul className="notice-list parallel-righht">
							<li>
								<UbtBoundary
									tracker={ChannelUbtGenerator(
										CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
										CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
										{ type: 'sericeNotice', text: '服务公告' }
									)}
								>
									<div className="notice-div-text-style">携程机票投诉受理方式</div>
								</UbtBoundary>
							</li>
							<li>
								<UbtBoundary
									tracker={ChannelUbtGenerator(
										CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
										CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
										{ type: 'sericeNotice', text: '服务公告' }
									)}
								>
									<div className="notice-div-text-style">机票投诉受理热线：95010</div>
								</UbtBoundary>
							</li>
							<li>
								<UbtBoundary
									tracker={ChannelUbtGenerator(
										CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
										CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
										{ type: 'sericeNotice', text: '服务公告' }
									)}
								>
									<div className="notice-div-text-style">机票投诉受理邮箱：jpwbss@trip.com</div>
								</UbtBoundary>
							</li>
						</ul>
					</div>
				</div>
				<div className="service-notice-right">
					<div className="download-app download-app-first">
						<div className="qrcode qrcode-app"></div>
						<div className="download-con">
							<h3>携程APP</h3>
							<p>享手机订单优惠</p>
							<p>随时随地查订单</p>
						</div>
					</div>
					<div className="download-app">
						<div className="qrcode qrcode-wechat"></div>
						<div className="download-con">
							<h3>携程特价机票</h3>
							<p>轻松出行必备</p>
							<p>定期放送特价</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServiceNotice;
